import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

type Props = {
  data: ImpressumQuery
}

export default ({ data }: Props) => {
  const position = {
    lat: 48.52018965370093,
    len: 13.45829795978668,
    zoom: 16
  }
  const phone = {
    number: "00436803256330",
    displayNumber: "+43 680 3256330"
  }

  return (
    <Layout siteTitle={data.site?.siteMetadata?.title || ""} phone={phone}>
      <SEO
        title={data.site?.siteMetadata?.title || ""}
        keywords={[`smarthome`, `koenig`, `nas`, `hausinstallation`, `elektriker`, `Andreas König`]}
      />
      <div className="bg-grey-200 relative">
        <div className="container mx-auto flex flex-col pt-8">
          <div className="text-grey-600 pb-16">
            <h1 className="m-auto my-4 text-4xl uppercase font-black">Impressum</h1>
            <h2 className="mt-6 mb-4 text-3xl">Angaben gemäß § 5 ECG:</h2>
            <p>Andreas König</p>
            <p>
              Schafberg 6B <br />
              4783 Wernstein am Inn
              <br />
              Oberösterreich
            </p>
            <h2 className="mt-6 mb-4 text-3xl">Kontakt</h2>
            <p>+43 680 3256330</p>
            <p>office@smarthome-koenig.at</p>
            <h2 className="mt-6 mb-4 text-3xl">Umsatzsteuer-ID</h2>
            <p>Umsatzsteuer-Identifikationsnummer: ATU75384978</p>
            <h2 className="mt-6 mb-4 text-3xl">Haftungsausschluss (Disclaimer)</h2>
            <p className="mt-4 mb-2 text-2xl">Haftung für Inhalte</p>
            <p>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
              Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
              rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
              Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
              Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
              konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <p className="mt-4 mb-2 text-2xl">Haftung für Links</p>
            <p>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir
              keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
              übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
              der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
              Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
              verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
              entfernen.
            </p>
            <p className="mt-4 mb-2 text-2xl">Urheberrecht</p>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
              unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung
              und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
              dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit
              die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
              gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
              werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Impressum {
    site {
      siteMetadata {
        title
        description
        image
        siteUrl
        telephone
      }
    }
  }
`
